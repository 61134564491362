
.App{
  text-align:left
}
.App-logo{
  height:40vmin;
  pointer-events:none
}
@media (prefers-reduced-motion:no-preference){
  .App-logo{
      -webkit-animation:App-logo-spin 20s linear infinite;
      animation:App-logo-spin 20s linear infinite
  }
}
.App-header{
  align-items:center;
  background-color:#282c34;
  color:#fff;
  display:flex;
  flex-direction:column;
  font-size:calc(10px + 2vmin);
  justify-content:center;
  min-height:100vh
}
.App-link{
  color:#61dafb
}
@-webkit-keyframes App-logo-spin{
  0%{
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
  }
  to{
      -webkit-transform:rotate(1turn);
      transform:rotate(1turn)
  }
}
@keyframes App-logo-spin{
  0%{
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
  }
  to{
      -webkit-transform:rotate(1turn);
      transform:rotate(1turn)
  }
}
/*# sourceMappingURL=main.e2a6b798.css.map*/