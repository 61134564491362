@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Rajdhani&display=swap');
body{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    font-family:'Open Sans',sans-serif;
    margin:0
}
button{
    position:relative;
    transition:all .25s;
    top:0;
    cursor:pointer;
    fill: #040208;
    color: #040208;
    background-color: #FFED4D;
    border-style: solid;
    border-width: 4px 0px 0px 0px;
    border-color: #D0BE1C;
    border-radius: 100px 100px 100px 100px;
    padding:16px 32px;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: bold;
}
button:before{
    content:"";
    position:relative;
    background-image:url('/public/angle-right.svg');
    width:13px;
    height:14px;
    display:block;
    background-size:contain;
    background-position:center;
    background-repeat:no-repeat;
    float:right;
    margin-left:1rem;
    top:3px;
    transition: all .25s;
}
button:hover:before{
    margin-left: 1.3rem;
}
#nft-price,p#mint-message{
    color:#fff;
}
select#nft-count {
    background:transparent;
    font-size:16px;
    color: #fff;
    padding: 16px 22px;
    border: 1px solid #fff;
    border-radius: 100px;
    margin-right: .5em;
}
select#nft-count:focus {
    border: 1px solid #fff;
    outline: 0;
}
span.wallet-address {
    font-size: .8em;
}
code{
    font-family:source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace
}
.hidden{
    display:none
}
.errorMsg{
    display:block
}

select#nft-count option {
    color: black;
}

#walletConnect {
    display: none;
  }

  @media only screen and (hover: none) and (pointer: coarse){
    #connect-wallet {
      display: none !important;
    }
    #walletConnect {
      display: block;
    }
  }

